import React from "react";
import AdsTitle from "./adstitle";
import AdsFeature from "./adsfeature";
import Presenter from "./presenter";
import "../hasuraads/styles.scss";
const AdsVideoTopBanner = () => {
  return (
    <section className="commonSectionWrapper wd100 commonGrayBgColor">
      <div className="containerWrapper">
        <div className="AdsVideoTopBannerWrapper">
          <div>
            <AdsTitle />
            <Presenter />
          </div>
          <div>
            <AdsFeature removePadd={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdsVideoTopBanner;
