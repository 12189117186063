import React from "react";
import { Link } from "gatsby";
import "./styles.scss";
import brandBlue from "../../images/blue-logo.svg";
import hasuraIcon from "./images/hasura-icon-blue.svg";
const WhatHasura = () => {
  return (
    <section className="commonLightGrayColor wd100">
      <div className="containerWrapper">
        <div className="whatHasuraWrapper">
          <div className="hasuraIcon">
            <img src={hasuraIcon} alt="Hasura icon" />
          </div>
          <div className="articleSubTitleHead">What is Hasura?</div>
          <div className="articleDesc">
            Hasura is an open-source engine that gives you instant GraphQL & REST APIs that unify
            your data and power modern applications
          </div>
        </div>
        <div className="adsFooterWrapper">
          <div className="footerBrand">
            <a href="/">
              <img src={brandBlue} alt="Hasura Logo" title="Hasura Logo" />
            </a>
          </div>
          <div className="articleDescSmall">
            © {new Date().getFullYear()} Hasura Inc. All rights reserved
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhatHasura;
