import React from "react";
import "../hasuraads/styles.scss";
import stevenYi from "../webinarvideos/images/steven-yi.png";
import peterDowns from "../webinarvideos/images/peter-downs.png";

const Presenter = () => {
  return (
    <div className="hostDetailWrapper">
      <div className="hostWrapper">
        <div className="articleDesc">Presented by</div>
        <div className="authorDetailsWrapper">
          <div className="authorImg">
            <img src={peterDowns} alt="Peter Downs" />
          </div>
          <div>
            <div className="articleDescSmall fontBold">Peter Downs</div>
            <div className="articleDescSmall">Director of Engineering at Pipe</div>
          </div>
        </div>
        <div className="authorDetailsWrapper">
          <div className="authorImg">
            <img src={stevenYi} alt="Steven Yi" />
          </div>
          <div>
            <div className="articleDescSmall fontBold">Steven Yi</div>
            <div className="articleDescSmall">Head of Product Marketing at Hasura</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presenter;
