import React from "react";
import "../hasuraads/styles.scss";
import setting from "../hasuraads/images/setting.svg";
import architecture from "../hasuraads/images/architecture.svg";
import next from "../hasuraads/images/next.svg";
const AdsFeature = ({ removePadd }) => {
  return (
    <div className={"adsFeatureWrapper" + (removePadd ? " removePaddTop" : "")}>
      <div className="adsFeatureList">
        <div className="adsFeatureImg">
          <img src={setting} alt="Setting" />
        </div>
        <div className="articleDesc blueColor fontBold">Why Hasura?</div>
        <div className="articleDesc">
          What challenges Pipe faced, why they chose GraphQL for their APIs, and why they chose Hasura.
        </div>
      </div>
      <div className="adsFeatureList">
        <div className="adsFeatureImg">
          <img src={architecture} alt="Architecture" />
        </div>
        <div className="articleDesc blueColor fontBold">Architecture</div>
        <div className="articleDesc">How Pipe architected their solution and the benefits they gained by utilizing Hasura.</div>
      </div>
      <div className="adsFeatureList">
        <div className="adsFeatureImg">
          <img src={next} alt="What’s Next" />
        </div>
        <div className="articleDesc blueColor fontBold">What’s Next</div>
        <div className="articleDesc">
          How they plan on using more of Hasura’s features in their product roadmap.
        </div>
      </div>
    </div>
  );
};

export default AdsFeature;
