import React from "react";
import "../hasuraads/styles.scss";
import AdsTitle from "./adstitle";
import AdsFeature from "./adsfeature";
import Presenter from "./presenter";
import Paperform from "../contactus/Paperform";
const AdsTopBannerRegister = () => {
  const onSubmitCB = () => {
    if (typeof window !== undefined) {
      window.localStorage.setItem("adsPipeFormConsent", "true");
    }
  };
  return (
    <div className="adsTopBanner">
      <div>
        <AdsTitle />
        <AdsFeature />
      </div>
      <div>
        <Paperform
          onSubmitCB={onSubmitCB}
          formId="hf-1025"
          styleClass="marketoFormWrapper webinarMarAuto"
        />
        <Presenter />
      </div>
    </div>
  );
};

export default AdsTopBannerRegister;
