import React from "react";
import { Link } from "gatsby";
import AdsTopBannerRegister from "./adstopbannerregister";
import "../hasuraads/styles.scss";
import brandBlue from "../../images/blue-logo.svg";
const TopBanner = () => {
  return (
    <section className="commonSectionWrapper wd100 adsBg removePaddTop">
      <div className="containerWrapper">
        <div className="adsBrand">
          <a href="/">
            <img src={brandBlue} alt="Hasura Logo" title="Hasura Logo" />
          </a>
        </div>
        <AdsTopBannerRegister />
      </div>
    </section>
  );
};

export default TopBanner;
