import React, { Fragment, useState, useEffect } from "react";
import Helmet from "react-helmet";
import Seo from "../components/seo";
import Layout from "../components/layout";
import TopBanner from "../components/hasurapipeads/topbanner";
import WhatHasura from "../components/hasuraads/whathasura";
import AdsVideoPlay from "../components/hasurapipeads/adsvideoplay";
import AdsVideoTopBanner from "../components/hasurapipeads/adsvideotopbanner";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-pipe-ads.png",
};
const canonicalUrl = "https://hasura.io/hasura-pipe-webinar/";

const HasuraPipeWebinar = props => {
  const search = props.location.search;
  const [isAliId, setIsAliId] = useState(false);
  const [isLocalAliId, setIsLocalAliId] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const searchAliId = searchParams.get("aliId");
    if (searchAliId || searchAliId === "") {
      setIsAliId(true);
    }
    if (typeof window !== undefined) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const adsPipeFormConsent = window.localStorage.getItem("adsPipeFormConsent");
        if (adsPipeFormConsent) {
          setIsLocalAliId(true);
        }
      }
    }
  }, [search]);

  return (
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Seo
        title="Learn How Pipe Uses Hasura To Help Companies Grow On Their Terms"
        description="Hasura and Pipe joint webinar: Learn how Pipe is using Hasura to easily create GraphQL APIs to ship features quickly"
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      {isAliId && isLocalAliId ? (
        <Layout location={props.location}>
          <AdsVideoPlay />
          <AdsVideoTopBanner />
        </Layout>
      ) : (
        <Fragment>
          <TopBanner />
          <WhatHasura />
        </Fragment>
      )}
    </Fragment>
  );
};

export default HasuraPipeWebinar;
