import React from "react";
import "../hasuraads/styles.scss";
const AdsVideoPlay = () => {
  return (
    <section className="commonSectionWrapper wd100 adsVideoBg">
      <div className="containerWrapper">
        <div className="adsVideoPlayWrapper videoWrapper">
          <div className="videoAspectRatio">
            <iframe
              loading="lazy"
              title="11 engineers & Hasura Powering Pipe.com’s Explosive Growth"
              src="https://player.vimeo.com/video/535967073"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdsVideoPlay;
